{
  "Title of Office": "Titul úřadu",
  "Coat of Arms": "Znak mezinárodního řádu",
  "Maltese Cross": "Maltský kříž",
  "Coat of Arms Belgium": "Znak Belgie",
  "Coat of Arms Bohemia": "Znak Čech",
  "Coat of Arms Great Britain": "Znak Velké Británie",
  "Coat of Arms Italy": "Znak Itálie",
  "Coat of Arms France": "Znak Francie",
  "Coat of Arms Poland": "Znak Polska",
  "Coat of Arms USA": "Znak Spojených států amerických",
  "Melkite Cross": "Melchitský kříž",
  "Order of St. Lazarus Signature Generator": "Generátor podpisů Řádu sv. Lazara",
  "Name + RANK Postnominal Only": "Pouze jméno a hodnost",
  "Office": "Úřad",
  "Phone": "Telefon",
  "Email": "E-mail",
  "Logo Preference": "Preferované logo",
  "Jurisdiction": "Soudní obvod",
  "Save Form Input to URL": "Uložit vstup z formuláře do URL",
  "Copy Signature": "Kopírovat podpis",
  "or, if you prefer raw html:": "nebo, pokud upřednostňujete čistý HTML:",
  "Grand Magistry": "Velký magistrát",
  "Grand Priory of USA": "Velký priorát USA",
  "Grand Priory of Bohemia": "Velký priorát Čech",
  "Grand Priory of Belgium and Luxembourg": "Velký priorát Belgie a Lucemburska",
  "Grand Priory of Switzerland": "Velký priorát Švýcarska",
  "Grand Priory of France": "Velký priorát Francie",
  "Grand Priory of Great Britain": "Velký priorát Velké Británie",
  "Grand Priory of Hungary": "Velký priorát Maďarska",
  "Grand Priory of Italy": "Velký priorát Itálie",
  "Grand Priory of Monaco": "Velký priorát Monaka",
  "Grand Priory of Montenegro": "Velký priorát Černé Hory",
  "Grand Priory of the Netherlands": "Velký priorát Nizozemska",
  "Grand Priory of Poland": "Velký priorát Polska",
  "Grand Priory of Portugal": "Velký priorát Portugalska",
  "Grand Priory of the Portuguese-speaking countries of Africa": "Velký priorát portugalsky mluvících zemí Afriky",
  "Grand Priory of Spain": "Velký priorát Španělska",
  "Signature copied to clipboard!": "Podpis byl zkopírován do schránky!",
  "URL copied to clipboard, save as a bookmark to keep your form entries: \n\n {0}": "URL byla zkopírována do schránky. Uložte si ji jako záložku, abyste si uchovali své položky formuláře",
  "H.E. Chev. Full Name, KLJ": "J.E. Rytíř jméno, KLJ",
  "default_email": "oslj@orderofsaintlazarus.com",
  "default_jurisdiction": "cz_BO"
}
