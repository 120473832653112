{
  "Title of Office": "Titre de poste",
  "Coat of Arms": "Blason",
  "Maltese Cross": "Croix de Malte",
  "Coat of Arms Belgium": "Blason Belgique",
  "Coat of Arms Bohemia": "Blason Bohême",
  "Coat of Arms Great Britain": "Blason Grande-Bretagne",
  "Coat of Arms Italy": "Blason Italie",
  "Coat of Arms France": "Blason France",
  "Coat of Arms Poland": "Blason Pologne",
  "Coat of Arms USA": "Blason États-Unis",
  "Melkite Cross": "Croix Melkite",
  "Order of St. Lazarus Signature Generator": "Signature de l'Ordre de Saint-Lazare",
  "Name + RANK Postnominal Only": "Nom + RANG Postnominal seulement",
  "Office": "Bureau",
  "Phone": "Téléphone",
  "Email": "Email",
  "Logo Preference": "Préférence de logo",
  "Jurisdiction": "Juridiction",
  "Save Form Input to URL": "Enregistrer la saisie de formulaire dans l'URL",
  "Copy Signature": "Copier la signature",
  "or, if you prefer raw html:": "ou, si vous préférez du HTML brut :",
  "Grand Magistry": "Grand Magistère",
  "Grand Priory of USA": "Grand Prieuré des États-Unis",
  "Grand Priory of Bohemia": "Grand Prieuré de Bohême",
  "Grand Priory of Belgium and Luxembourg": "Grand Prieuré de Belgique et du Luxembourg",
  "Grand Priory of Switzerland": "Grand Prieuré de Suisse",
  "Grand Priory of France": "Grand Prieuré de France",
  "Grand Priory of Great Britain": "Grand Prieuré de Grande-Bretagne",
  "Grand Priory of Hungary": "Grand Prieuré de Hongrie",
  "Grand Priory of Italy": "Grand Prieuré d'Italie",
  "Grand Priory of Monaco": "Grand Prieuré de Monaco",
  "Grand Priory of Montenegro": "Grand Prieuré du Monténégro",
  "Grand Priory of the Netherlands": "Grand Prieuré des Pays-Bas",
  "Grand Priory of Poland": "Grand Prieuré de Pologne",
  "Grand Priory of Portugal": "Grand Prieuré du Portugal",
  "Grand Priory of the Portuguese-speaking countries of Africa": "Grand Prieuré des pays africains de langue portugaise",
  "Grand Priory of Spain": "Grand Prieuré d'Espagne",
  "Signature copied to clipboard!": "Signature copiée dans le presse-papiers !",
  "URL copied to clipboard, save as a bookmark to keep your form entries: \n\n {0}": "URL copiée dans le presse-papiers, enregistrez-la comme un favori pour conserver vos saisies de formulaire",
  "H.E. Chev. Full Name, KLJ": "H.E. Chev. Nom et prénom, KLJ",
  "default_email": "oslj@orderofsaintlazarus.com",
  "default_jurisdiction": "int"
}
