import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import { toBlob } from 'html-to-image';
import { i18n } from '~/scripts/locale';
import { toast } from 'react-toastify';

const copy = async (type, ref) => {
  // Get the rendered table element
  const signatureTable = document.querySelector('#lzsig');
  try {
    switch (type) {
      case 'text': {
        // Create a range object to select the table content
        const range = document.createRange();
        range.selectNodeContents(signatureTable);

        // Create a selection object and add the range to it
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        // Copy the selected content to the clipboard
        document.execCommand('copy');

        // Clean up the selection
        selection.removeAllRanges();
        break;
      }
      case 'html': {
        await navigator.clipboard.writeText(document.querySelector('textarea#html').value);
        break;
      }
      case 'image':
        if (ref.current === null) {
          return;
        }
        const blob = await toBlob(ref.current, { cacheBust: true });
        await navigator.clipboard.write([
          new ClipboardItem({
            'image/png': blob,
          }),
        ]);
        break;
      case 'base64':
        toast('Not yet implemented! Check back soon!', { type: 'warning' });
        return;
      default:
        break;
    }
    // Show a success message or perform any other actions as needed
    toast(i18n`Signature copied to clipboard!`);
  } catch (e) {
    // Uncomment below to debug
    // console.error(e);
    // console.trace();
    toast(`Could not copy signature as ${type}`, { type: 'error' });
  }
};

const CopyButton = ({ onClick, sigref }) => {
  return (
    <Dropdown as={ButtonGroup}>
      <Button variant="success" onClick={() => onClick('text')}>{i18n`Copy Signature`}</Button>
      <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
      <Dropdown.Menu>
        <Dropdown.Item id="sig-raw" onClick={() => onClick('text', sigref)}>
          {i18n`As text`}
        </Dropdown.Item>
        <Dropdown.Item id="sig-raw" onClick={() => onClick('html', sigref)}>
          {i18n`As raw HTML`}
        </Dropdown.Item>
        <Dropdown.Item id="sig-png" onClick={() => onClick('image', sigref)}>
          {i18n`As an Image`}
        </Dropdown.Item>
        <Dropdown.Item id="sig-b64" disabled onClick={() => onClick('base64', sigref)}>
          {i18n`As base64 png`}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
CopyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  sigref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]).isRequired,
};

const HtmlArea = () => {
  return (
    <>
      <label htmlFor="html" id="html-label" style={{ visibility: 'hidden' }}>
        {i18n`or, if you prefer raw html:`}
      </label>
      <textarea id="html" spellCheck="false" style={{ visibility: 'hidden' }} />
    </>
  );
};

export const CopyCode = ({ sigref }) => {
  return (
    <div className="code">
      <CopyButton id="copySignature" onClick={copy} sigref={sigref} />
      <HtmlArea />
    </div>
  );
};
CopyCode.propTypes = {
  sigref: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};
