import { i18n } from '../scripts/locale';

export const jurisdictions = {
  int: {
    jurisdictionName: 'Grand Magistry',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.OrderofSaintLazarus.com',
    href: 'https://www.orderofsaintlazarus.com/',
    display: i18n`Grand Magistry`,
  },
  en_US: {
    jurisdictionName: 'Grand Priory of USA',
    orderName: 'The Military and Hospitaller Order of Saint Lazarus of Jerusalem',
    websiteName: 'www.OrderofSaintLazarusUSA.org',
    href: 'https://OrderofSaintLazarusUSA.org',
    display: i18n`Grand Priory of USA`,
  },
  cz_BO: {
    jurisdictionName: 'České Velkopřevorství',
    orderName: 'Vojenský a špitální Řádsvatého Lazara Jeruzalémského',
    websiteName: 'www.oslj.cz',
    href: 'http://www.oslj.cz/',
    display: i18n`Grand Priory of Bohemia`,
  },
  fr_BE_LU: {
    jurisdictionName: 'Le Grand Prieuré du Royaume de Belgique',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.orderofsaintlazarus.be',
    href: 'https://www.orderofsaintlazarus.be/',
    display: i18n`Grand Priory of Belgium and Luxembourg`,
  },
  _CH: {
    jurisdictionName: 'Grand Priory of Switzerland',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.OrderofSaintLazarus.com',
    href: 'https://www.OrderofSaintLazarus.com/',
    display: i18n`Grand Priory of Switzerland`,
  },
  fr_FR: {
    jurisdictionName: 'Grand Prieuré de France',
    orderName: 'l’Ordre Militaire et Hospitalier de Saint Lazare de Jérusalem',
    websiteName: 'www.ordredesaintlazare.com',
    href: 'http://www.ordredesaintlazare.com/',
    display: i18n`Grand Priory of France`,
  },
  en_GB: {
    jurisdictionName: 'Grand Priory of Great Britain',
    orderName: 'The Military and Hospitaller Order of Saint Lazarus of Jerusalem',
    websiteName: 'www.oslj.org.uk',
    href: 'http://www.oslj.org.uk',
    display: i18n`Grand Priory of Great Britain`,
  },
  hungary: {
    jurisdictionName: 'Grand Priory of Hungary',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.OrderofSaintLazarus.com',
    href: 'https://www.OrderofSaintLazarus.com/',
    display: i18n`Grand Priory of Hungary`,
  },
  it_IT: {
    jurisdictionName: "Gran Priore d'Italia",
    orderName: 'Ordine Militare ed Ospedaliero di San Lazzaro di Gerusalemme',
    websiteName: 'www.oslj-italia.it',
    href: 'http://www.oslj-italia.it/',
    display: i18n`Grand Priory of Italy`,
  },
  monaco: {
    jurisdictionName: 'Grand Priory of Monaco',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.OrderofSaintLazarus.com',
    href: 'https://www.OrderofSaintLazarus.com/',
    display: i18n`Grand Priory of Monaco`,
  },
  montenegro: {
    jurisdictionName: 'Grand Priory of Montenegro',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.OrderofSaintLazarus.com',
    href: 'https://www.OrderofSaintLazarus.com/',
    display: i18n`Grand Priory of Montenegro`,
  },
  nl_NL: {
    jurisdictionName: 'Grand Priory of the Netherlands',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.OrderofSaintLazarus.com',
    href: 'https://www.OrderofSaintLazarus.com/',
    display: i18n`Grand Priory of the Netherlands`,
  },
  pl: {
    jurisdictionName: 'Wielki Przeorat Polski',
    orderName: 'Rycerski i Szpitalny Zakon świętego Łazarza z Jerozolimy',
    websiteName: 'www.lazarus.pl/',
    href: 'https://www.lazarus.pl/',
    display: i18n`Grand Priory of Poland`,
  },
  pt_PT: {
    jurisdictionName: 'Grand Priory of Portugal',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.OrderofSaintLazarus.com',
    href: 'https://www.OrderofSaintLazarus.com/',
    display: i18n`Grand Priory of Portugal`,
  },
  pt_AF: {
    jurisdictionName: 'Grand Priory of the Portuguese-speaking countries of Africa',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.OrderofSaintLazarus.com',
    href: 'https://www.OrderofSaintLazarus.com/',
    display: i18n`Grand Priory of the Portuguese-speaking countries of Africa`,
  },
  es_SP: {
    jurisdictionName: 'Grand Priory of Spain',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.OrderofSaintLazarus.com',
    href: 'https://www.OrderofSaintLazarus.com/',
    display: i18n`Grand Priory of Spain`,
  },
  secretariat: {
    jurisdictionName: 'Secretariat of the Grand Chancellery',
    orderName: 'Ordo Militaris et Hospitalaris Sancti Lazari Hierosolymitani',
    websiteName: 'www.OrderofSaintLazarus.com',
    href: 'https://www.orderofsaintlazarus.com/',
    display: i18n`Secretariat of the Grand Chancellery`,
    disabled: false,
  },
};

export const options = Object.entries(jurisdictions)
  .filter(([, value]) => !value.disabled)
  .map(([key, value]) => {
    const option = document.createElement('option');
    option.value = key;
    option.appendChild(document.createTextNode(value.display));
    return option;
  });
