import { i18n } from '../scripts/locale';

// this is an ordered object. the order of the entries here
//   determines the order of entries in the logo preference selection
export const logos = {
  international: {
    imageName: 'Armes+INT_141.png',
    display: i18n`Coat of Arms`,
  },
  maltese: {
    imageName: 'Lazarus_cross_141.png',
    display: i18n`Maltese Cross`,
  },
  ecusson: {
    imageName: 'Ecusson_141.png',
    display: i18n`Écusson of the Order`,
  },
  shield: {
    imageName: 'Shield_141.png',
    display: i18n`Shield of the Order`,
  },
  insignia: {
    imageName: 'Insignia_141.png',
    display: i18n`Insignia of the Order`,
  },
  seal: {
    imageName: 'Seal_141.png',
    display: i18n`Seal of the Order`,
  },
  belgium: {
    imageName: 'Armes+Belgium_141.png',
    display: i18n`Coat of Arms Belgium`,
  },
  bohemia: {
    imageName: 'Armes+CZ_141.png',
    display: i18n`Coat of Arms Bohemia`,
  },
  cr: {
    imageName: 'Armes+CR_141.png',
    display: i18n`Coat of Arms Costa Rica`,
  },
  france: {
    imageName: 'Armes+France_141.png',
    display: i18n`Coat of Arms France`,
  },
  britain: {
    imageName: 'Armes+Britain_141.png',
    display: i18n`Coat of Arms Great Britain`,
  },
  hungary: {
    imageName: 'Armes+Hungary_141.png',
    display: i18n`Coat of Arms Hungary`,
  },
  italy: {
    imageName: 'Armes+Italy_141.png',
    display: i18n`Coat of Arms Italy`,
  },
  monaco: {
    imageName: 'Armes+Monaco_141.png',
    display: i18n`Coat of Arms Monaco`,
  },
  netherlands: {
    imageName: 'Armes+Netherlands_141.png',
    display: i18n`Coat of Arms the Netherlands`,
  },
  poland: {
    imageName: 'Armes+Poland_141.png',
    display: i18n`Coat of Arms Poland`,
  },
  spain: {
    imageName: 'Armes+Spain_141.png',
    display: i18n`Coat of Arms Spain`,
  },
  usa: {
    imageName: 'Armes+USA_141.png',
    display: i18n`Coat of Arms USA`,
  },
  melkite: {
    imageName: 'Melkite_cross_141.png',
    disabled: true,
    display: i18n`Melkite Cross`,
  },
  fleurdelazare: {
    imageName: 'Fleur_de_Lazare_141.png',
    disabled: true,
    display: i18n`Maltese Cross with Fleur de Lis`,
  },
};

export default logos;

export const options = Object.entries(logos)
  .filter(([, value]) => !value.disabled)
  .map(([key, value]) => {
    const option = document.createElement('option');
    option.value = key;
    option.appendChild(document.createTextNode(value.display));
    return option;
  });
