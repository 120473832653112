import Form from 'react-bootstrap/Form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { i18n } from '~/scripts/locale';

const BorderToggle = ({ showBorder, setShowBorder }) => {
  const toggleRef = useCallback(() => setShowBorder(!showBorder), [showBorder]);
  const switchLabel = useCallback(() => (showBorder ? i18n`Showing Border` : i18n`HidingBorder`), [showBorder]);
  return (
    <Form.Check
      style={{ display: 'inline-block' }}
      type="checkbox"
      id="show-border"
      label={switchLabel()}
      onClick={toggleRef}
      defaultChecked={showBorder}
    />
  );
};
BorderToggle.propTypes = {
  showBorder: PropTypes.bool.isRequired,
  setShowBorder: PropTypes.func.isRequired,
};

export default BorderToggle;
