{
  "name": "lazarus-signature-generator",
  "version": "1.10.1",
  "description": "Signature Generator for the Knights of Saint Lazarus",
  "source": "sig.html",
  "private": true,
  "scripts": {
    "build": "parcel build",
    "docker-build": "docker build -t signature-generator .",
    "docker-run": "docker run -p 8000:8000 signature-generator",
    "fmt": "prettier -c .",
    "fmt:fix": "prettier -w .",
    "lint": "eslint .",
    "lint:fix": "eslint --fix .",
    "prepare": "husky install",
    "start": "parcel -p 8000",
    "validate": "npm run lint"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/tvoboril/Lazarus-Signature-Generator.git"
  },
  "author": "tvoboril",
  "license": "ISC",
  "bugs": {
    "url": "https://github.com/tvoboril/Lazarus-Signature-Generator/issues"
  },
  "homepage": "https://github.com/tvoboril/Lazarus-Signature-Generator#readme",
  "devDependencies": {
    "@parcel/packager-raw-url": "^2.8.3",
    "@parcel/transformer-sass": "^2.8.3",
    "@parcel/transformer-webmanifest": "^2.8.3",
    "bootstrap": "^5.3.0",
    "eslint": "^8.41.0",
    "eslint-config-airbnb": "^19.0.4",
    "eslint-config-prettier": "^8.8.0",
    "eslint-import-resolver-alias": "^1.1.2",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-jsx-a11y": "^6.7.1",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-react": "^7.32.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "html-to-image": "^1.11.11",
    "husky": "^8.0.3",
    "i18n-string-templates": "^1.0.6",
    "lint-staged": "^13.2.2",
    "parcel": "latest",
    "precommit-hook": "^3.0.0",
    "prettier": "^2.8.8",
    "process": "^0.11.10",
    "prop-types": "^15.8.1",
    "react": "^18.2.0",
    "react-bootstrap": "^2.7.4",
    "react-dom": "^18.2.0",
    "react-router-dom": "^6.11.2",
    "react-toastify": "^9.1.3"
  },
  "release": {
    "plugins": [
      "@semantic-release/commit-analyzer",
      "@semantic-release/release-notes-generator",
      [
        "@semantic-release/npm",
        {
          "npmPublish": false
        }
      ],
      "@semantic-release/github",
      [
        "@semantic-release/changelog",
        {
          "assets": [
            "CHANGELOG.md"
          ]
        }
      ],
      [
        "@semantic-release/git",
        {
          "assets": [
            "package.json",
            "package-lock.json",
            "CHANGELOG.md"
          ]
        }
      ]
    ],
    "branches": "main"
  },
  "prettier": {
    "singleQuote": true,
    "quoteProps": "as-needed",
    "trailingComma": "es5",
    "bracketSpacing": true,
    "arrowParens": "always",
    "printWidth": 120,
    "semi": true
  },
  "eslintIgnore": [
    "dist/"
  ],
  "eslintConfig": {
    "env": {
      "browser": true,
      "es2021": true
    },
    "extends": [
      "airbnb",
      "plugin:prettier/recommended"
    ],
    "parserOptions": {
      "ecmaVersion": "latest",
      "sourceType": "module"
    },
    "rules": {
      "prettier/prettier": [
        "error",
        {},
        {
          "usePrettierrc": true
        }
      ],
      "import/prefer-default-export": "off",
      "react/react-in-jsx-scope": "off",
      "react/function-component-definition": "off",
      "arrow-body-style": "off",
      "prefer-arrow-callback": "off",
      "jsx-a11y/label-has-associated-control": "off",
      "import/no-extraneous-dependencies": "off",
      "no-case-declarations": "off"
    },
    "settings": {
      "import/resolver": {
        "alias": {
          "extensions": [
            ".js",
            ".jsx"
          ],
          "map": [
            [
              "~",
              "."
            ]
          ]
        }
      }
    }
  },
  "lint-staged": {
    "*.{js,vue}": "eslint --cache",
    "*.**": "prettier --check --ignore-unknown"
  },
  "pre-commit": [
    "validate",
    "fmt"
  ]
}
