{
  "Title of Office": "Title of Office",
  "Coat of Arms": "Coat of Arms",
  "Maltese Cross": "Maltese Cross",
  "Coat of Arms Belgium": "Coat of Arms Belgium",
  "Coat of Arms Bohemia": "Coat of Arms Bohemia",
  "Coat of Arms Great Britain": "Coat of Arms Great Britain",
  "Coat of Arms Italy": "Coat of Arms Italy",
  "Coat of Arms France": "Coat of Arms France",
  "Coat of Arms Poland": "Coat of Arms Poland",
  "Coat of Arms USA": "Coat of Arms USA",
  "Melkite Cross": "Melkite Cross",
  "Maltese Cross with Fleur de Lis": "Maltese Cross with Fleur de Lis",
  "Order of St. Lazarus Signature Generator": "Order of St. Lazarus Signature Generator",
  "Name + RANK Postnominal Only": "Name + RANK Postnominal Only",
  "Office": "Office",
  "Phone": "Phone",
  "Email": "Email",
  "Logo Preference": "Logo Preference",
  "Jurisdiction": "Jurisdiction",
  "Save Form Input to URL": "Save Form Input to URL",
  "Copy Signature": "Copy Signature",
  "or, if you prefer raw html:": "or, if you prefer raw html:",
  "Grand Magistry": "Grand Magistry",
  "Grand Priory of USA": "Grand Priory of USA",
  "Grand Priory of Bohemia": "Grand Priory of Bohemia",
  "Grand Priory of Belgium and Luxembourg": "Grand Priory of Belgium and Luxembourg",
  "Grand Priory of Switzerland": "Grand Priory of Switzerland",
  "Grand Priory of France": "Grand Priory of France",
  "Grand Priory of Great Britain": "Grand Priory of Great Britain",
  "Grand Priory of Hungary": "Grand Priory of Hungary",
  "Grand Priory of Italy": "Grand Priory of Italy",
  "Grand Priory of Monaco": "Grand Priory of Monaco",
  "Grand Priory of Montenegro": "Grand Priory of Montenegro",
  "Grand Priory of the Netherlands": "Grand Priory of the Netherlands",
  "Grand Priory of Poland": "Grand Priory of Poland",
  "Grand Priory of Portugal": "Grand Priory of Portugal",
  "Grand Priory of the Portuguese-speaking countries of Africa": "Grand Priory of the Portuguese-speaking countries of Africa",
  "Grand Priory of Spain": "Grand Priory of Spain",
  "Signature copied to clipboard!": "Signature copied to clipboard!",
  "URL copied to clipboard, save as a bookmark to keep your form entries: \n\n {0}": "URL copied to clipboard, save as a bookmark to keep your form entries",
  "H.E. Chev. Full Name, KLJ": "H.E. Chev. Full Name, KLJ",
  "default_email": "oslj@orderofsaintlazarususa.org",
  "default_jurisdiction": "en_US",
  "As text": "As text",
  "As raw HTML": "As raw HTML",
  "As an Image": "As an Image",
  "As base64 png": "As base64 png"
}
