import PropTypes from 'prop-types';

const base = {
  text: {
    fontFamily: "'Arial',sans-serif",
    fontWeight: '600',
    verticalAlign: 'baseline',
    fontSize: '8.5pt',
  },
};
const styles = {
  lzsig: {
    margin: '0 0 6px 0',
    padding: '4px',
    border: 0,
    minWidth: '570px',
    background: 'white',
    height: '180px',
    borderRadius: '10px',
  },
  lzlogo: {
    padding: '0 20px 0 20px',
  },
  lzname: {
    fontSize: '11pt',
    fontFamily: base.text.fontFamily,
    color: '#008452',
    fontWeight: '700',
    verticalAlign: base.text.verticalAlign,
    marginBottom: '3px',
  },
  wrapperTd0: {
    padding: '0 0 0 20px',
  },
  lztitle: {
    ...base.text,
  },
  lzorder: {
    color: '#008452',
    ...base.text,
  },
  icon: {
    marginTop: '-1px',
    marginRight: '8px',
  },
  lzemail: {
    color: 'black',
    textDecoration: 'none',
  },
  lzwebsite: {
    color: '#008452',
    textDecoration: 'none',
  },
  lzjurisdiction: {
    marginBottom: '5px',
    ...base.text,
  },
  lzcontact: {
    lineHeight: '12pt',
    color: '#434343',
    verticalAlign: base.text.verticalAlign,
    fontFamily: base.text.fontFamily,
    fontSize: base.text.fontSize,
  },
  divider: {
    height: '130px',
    border: 0,
    borderRightWidth: '1pt',
    borderRightStyle: 'solid',
    borderRightColor: '#008452',
  },
};

export const SignatureDisplay = ({ sigref, showBorder }) => {
  return (
    <div className="wrapper">
      <div>
        <table id="lzsig" width="100%" style={styles.lzsig} ref={sigref}>
          <tbody>
            <tr>
              <td width="33" style={styles.lzlogo}>
                <img className="lz-logo" width="auto" height="130" alt="Order of St. Lazarus" />
              </td>
              <td width="1">
                <div style={{ ...styles.divider, visibility: showBorder ? 'visible' : 'hidden' }} />
              </td>
              <td style={styles.wrapperTd0}>
                <div style={styles.lzname} className="lz-name" />
                <div style={styles.lztitle} className="lz-title" />
                <div style={styles.lzorder} className="lz-order" />
                <div style={styles.lzjurisdiction} className="lz-jurisdiction" />
                <div className="lz-contact" style={styles.lzcontact}>
                  <img
                    src="https://storage.googleapis.com/lazarus-assets/phone_green.png"
                    alt="ph:"
                    width="12"
                    height="12"
                    style={styles.icon}
                  />
                  <span className="lz-phone" />
                  <br />
                  <img
                    src="https://storage.googleapis.com/lazarus-assets/email_green.png"
                    alt="em:"
                    width="12"
                    height="12"
                    style={styles.icon}
                  />
                  <a className="lz-email" href="mailto:admin@OrderofSaintLazarusUSA.org" style={styles.lzemail}>
                    {' '}
                  </a>
                  <br />
                  <img
                    src="https://storage.googleapis.com/lazarus-assets/web_green.png"
                    alt="www:"
                    width="12"
                    height="12"
                    style={styles.icon}
                  />
                  <a className="lz-website" href="https://orderofsaintlazarususa.org/" style={styles.lzwebsite}>
                    {' '}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
SignatureDisplay.propTypes = {
  sigref: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  showBorder: PropTypes.bool.isRequired,
};
