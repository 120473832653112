import use from 'i18n-string-templates';
import locales from '~/data/locales';

const urlParams = new URLSearchParams(window.location.hash || window.location.search);
const supportedLocales = ['en', 'fr', 'cs'];

// eslint-disable-next-line no-nested-ternary
const browserLocale = locales[navigator.language]
  ? navigator.language
  : locales[navigator.language.substring(0, 2)]
  ? navigator.language.substring(0, 2)
  : 'en';
export const locale = supportedLocales.includes(urlParams.get('lang')) ? urlParams.get('lang') : browserLocale;

export default locale;
export const i18n = use(locales, locale);
