import { jurisdictions, options as jurisdictionOptions } from '~/data/jurisdictions';
import { logos, options as logoOptions } from '~/data/logos';
import { i18n } from '~/scripts/locale';
import fromUrlParams from './fromUrlParams';

const prepop = (fields) => {
  Object.keys(fields).forEach((k) => {
    const e = document.querySelector(`#${k}`);
    if (e) {
      e.value = fields[k];
    }
  });
};
const defaults = {
  name: i18n`H.E. Chev. Full Name, KLJ`,
  title: i18n`Title of Office`,
  phone: '+1 332.334.7332',
  email: i18n`default_email`,
  logo: 'international',
  jurisdiction: i18n`default_jurisdiction`, // see keys in jurisdictions.js
};
const redraw = (fields, first) => {
  const baseUrl = process.env.BASE_URL || 'https://storage.googleapis.com/lazarus-assets';
  Object.keys(fields).forEach((key) => {
    const e = document.querySelector(`.lz-${key}`);
    if (e) {
      switch (key) {
        case 'logo':
          e.setAttribute('src', `${baseUrl}/${logos[fields[key]].imageName}`);
          break;
        case 'jurisdiction':
          const { jurisdictionName, websiteName, href, orderName } = jurisdictions[fields[key]];
          document.querySelector('.lz-jurisdiction').innerHTML = jurisdictionName;
          document.querySelector('.lz-order').innerHTML = orderName;
          document.querySelector('.lz-website').innerHTML = websiteName;
          document.querySelector('.lz-website').setAttribute('href', href);
          break;
        case 'email':
          e.setAttribute('href', `mailto:${fields[key]}`);
        // DON'T BREAK - This needs to fall through
        default:
          e.innerHTML = fields[key];
      }
    }
  });
  document.querySelector('textarea#html').value = document
    .querySelector('#lzsig')
    .outerHTML.split('\n')
    .map((line) => line.replaceAll(/\s\s+/gi, ''))
    .join('\n');

  const q = new URLSearchParams();
  Object.keys(defaults).forEach((key) => {
    const e = document.querySelector(`#${key}`);
    const value = first ? fields[key] || e?.value : e?.value || fields[key];
    if (value?.length && value !== defaults[key]) {
      q.set(key, encodeURIComponent(value));
    }
  });
  document.location.hash = q.toString();
  return fields;
};

export const init = () => {
  const handler = (ev) => redraw(Object.fromEntries([[ev.target.getAttribute('id'), ev.target.value]]));
  document.addEventListener('keyup', handler);
  document.addEventListener('change', handler);

  // update logo selections with language options
  const logoSelect = document.getElementById('logo');
  while (logoSelect.lastElementChild) {
    logoSelect.removeChild(logoSelect.lastElementChild);
  }
  logoOptions.forEach((option) => logoSelect.appendChild(option));

  // update jurisdiction selections with language options
  const jurisdictionSelect = document.getElementById('jurisdiction');
  while (jurisdictionSelect.lastElementChild) {
    jurisdictionSelect.removeChild(jurisdictionSelect.lastElementChild);
  }
  jurisdictionOptions.forEach((option) => jurisdictionSelect.appendChild(option));
  const existing = fromUrlParams(document.location.search || document.location.hash.replace('#', '?'));
  prepop(redraw({ ...defaults, ...existing }, true));
};
