import en from './lang/en.json';
import fr from './lang/fr.json';
import cs from './lang/cz.json';

export default {
  en,
  fr,
  cs,
  en_US: en,
  'en-US': en,
  fr_FR: fr,
  'fr-FR': fr,
  cs_CZ: cs,
  'cs-CZ': cs,
};
