import { i18n } from '~/scripts/locale';
import { version } from '~/package.json';
import { toast } from 'react-toastify';

const getField = (/** @type {string} */ field) => {
  return encodeURIComponent(document.getElementById(field).value);
};

function copyUrl() {
  const name = getField('name');
  const title = getField('title');
  const phone = getField('phone');
  const email = getField('email');
  const logo = getField('logo');
  const jurisdiction = getField('jurisdiction');

  // Get the base URL of the page
  const baseUrlCopy = window.location.origin;

  // Construct the URL to be copied and redirected to
  const keys = {
    name,
    title,
    phone,
    email,
    logo,
    jurisdiction,
  };
  const urlCopy = `${baseUrlCopy}/#${Object.entries(keys)
    .filter(([, v]) => !!v)
    .map(([k, v]) => `${k}=${v}`)
    .join('&')}`;

  // Create a temporary input element to copy the text to clipboard
  const tempInput = document.createElement('input');
  tempInput.setAttribute('type', 'text');
  tempInput.setAttribute('value', urlCopy);
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);

  // Show alert after copying to clipboard
  toast(i18n`URL copied to clipboard, save as a bookmark to keep your form entries: \n\n ${urlCopy}`);

  return false; // Prevent form submission and page refresh
}

export const Generator = () => {
  return (
    <div className="generator">
      <div>
        <h1 id="page-title">{i18n`Order of St. Lazarus Signature Generator`}</h1>
        <form onSubmit={(event) => event.preventDefault()}>
          <div>
            <label htmlFor="name" id="name-label">
              {i18n`Name + RANK Postnominal Only`}
            </label>
            <input type="text" id="name" />
          </div>
          <div>
            <label htmlFor="title" id="title-label">
              {i18n`Office`}
            </label>
            <input type="text" id="title" />
          </div>
          <div>
            <label htmlFor="phone" id="phone-label">
              {i18n`Phone`}
            </label>
            <input type="text" id="phone" />
          </div>
          <div>
            <label htmlFor="email" id="email-label">
              {i18n`Email`}
            </label>
            <input type="text" id="email" />
          </div>
          <div>
            <label htmlFor="logo" id="logo-label">
              {i18n`Logo Preference`}
            </label>
            <select id="logo" aria-label={i18n`Logo Preference`} />
          </div>
          <div>
            <label htmlFor="jurisdiction" id="jurisdiction-label">
              {i18n`Jurisdiction`}
            </label>
            <select id="jurisdiction" aria-label={i18n`Jurisdiction`} />
          </div>
          <div>
            <a id="copyright" target="_blank" href="https://orderofsaintlazarususa.org/" rel="noreferrer">
              © SAINT LAZARE USA, INC 2024
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              Version: <span id="version">{version}</span>
            </a>
          </div>
          <div className="copy-url">
            <button type="button" id="save-form-input" onClick={copyUrl}>
              {i18n`Save Form Input to URL`}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
